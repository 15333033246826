import { createRouter, createWebHistory } from "vue-router";
import AlumniLp from "@/views/AlumniLp.vue"
import alumnity from "@/views/thankyou/alumnity.vue";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: () =>
    import(/* webpackChunkName: "page-not-found" */ "@/views/PageNotFoundView.vue"),
  },
  {
    path: "/:slug",
    name: "ProgramView",
    component: () =>
    import(/* webpackChunkName: "/:slug" */ "@/views/ProgramsView.vue"),
    props: true,
},
 {
    path: "/",
    name: "home",
    component: () =>
    import(/* webpackChunkName: "index" */ "@/views/HomeView.vue"),
  },
  {
    path: "/lp/alumni",
    name: "AlumniLp",
    component: AlumniLp,
  },
    {
    path: "/lp/thankyou/alumni",
    name: "alumnity",
    component: alumnity,
    props: true,
  },

  {
    path: "/online-degree-programs",
    name: "DegreeProgramView",
    component: () =>
    import(/* webpackChunkName: "online-degree-programs" */ "@/views/OnlineProgramView.vue"),
  },

  {
    path: "/online-mba/:slug",
    name: "MbaView",
    component: () =>
    import(/* webpackChunkName: "online-mba/:slug" */ "@/views/MbaView.vue"),
  },
  {
    path: "/online-mca/:slug",
    name: "McaView",
    component: () =>
    import(/* webpackChunkName: "online-mca/:slug" */ "@/views/McaView.vue"),
  },
  {
    path: "/online-bba/:slug",
    name: "BbaView",
    component: () =>
    import(/* webpackChunkName: "online-bba/:slug" */ "@/views/BbaView.vue"),
  },
  {
    path: "/online-bca/:slug",
    name: "BcaView",
    component: () =>
    import(/* webpackChunkName: "online-bca/:slug" */ "@/views/BcaView.vue"),
  },
  {
    path: "/about-us",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about-us" */ "@/views/AboutView.vue"),
  },
  {
    path: "/contact-us",
    name: "contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "contact-us" */ "@/views/ContactView.vue"),
  },
  {
    path: "/skills",
    name: "SkillsView",
    component: () =>
    import(/* webpackChunkName: "skills" */ "@/views/SkillsView.vue"),
  },
  {
    path: "/blogs",
    name: "MainBlogsView",
    component: () =>
    import(/* webpackChunkName: "blogs" */ "@/views/MainBlogs.vue"),
  },
  {
    path: "/blogs/:blog/",
    name: "BlogsView",
    component: () =>
    import(/* webpackChunkName: "blogs/:blog/" */ "@/views/BlogView.vue"),
    props: true,
 
  },
  {
    path: "/privacypolicy",
    name: "PrivacyPolicy",
    component: () =>
    import(/* webpackChunkName: "privacypolicy" */ "@/views/PrivacyPolicy.vue"),
  },
  {
    path: "/feedback-and-grievance-management",
    name: "FgmView",
    component: () =>
    import(/* webpackChunkName: "feedback-and-grievance-management" */ "@/views/FgmView.vue"),
  },
  {
    path: "/past-enrolments",
    name: "PenrolmentView",
    component: () =>
    import(/* webpackChunkName: "past-enrolments" */ "@/views/PenrolmentView.vue"),
  },
  {
    path: "/quality-and-assurance",
    name: "QualityView",
    component: () =>
    import(/* webpackChunkName: "quality-and-assurance" */ "@/views/QualityView.vue"),
  },
  {
    path: "/student-corner",
    name: "StudentCornerView",
    component: () =>
    import(/* webpackChunkName: "student-corner" */ "@/views/StudentCornerView.vue"),
  },
  {
    path: "/statutory-disclosures",
    name: "DisclosureView",
    component: () =>
    import(/* webpackChunkName: "index" */ "@/views/DisclosureView.vue"),
  },
  {
    path: "/refundpolicy",
    name: "RefundPolicy",
    component: () =>
    import(/* webpackChunkName: "index" */ "@/components/RefundPolicy.vue"),
  },
  {
    path: "/lp/:slug",
    name: "NewLpView",
    component: () =>
    import(/* webpackChunkName: "lp/:slug" */ "@/views/NewLpView.vue"),
  },
  {
    path: "/lp/vignanodl",
    name: "vignanodl",
    component: () =>
    import(/* webpackChunkName: "lp/vignanodl" */ "@/views/OdlView.vue"),
  },
  {
    path: "/lp/online-program.html",
    name: "LpnewView",
    component: () =>
      import(/* webpackChunkName: "AllProgram" */ "@/views/LpnewView.vue"),
  },
  {
    path: "/lp/thank-you",
    name: "lpThankyou",
    component: () =>
      import(
        /* webpackChunkName: "AllProgram" */ "@/views/LpnewThankyouView.vue"
      ),
  },
  {
    path: "/lp/thankyou/:slug",
    name: "ThankYouNewlp",
    component: () =>
    import(/* webpackChunkName: "lp/thankyou/:slug" */ "@/views/ThankYouNewlp.vue"),
    props: true,
  },
  {
    path: "/lp/:slug.html",
    name: "LP_MBA",
    component: () =>
    import(/* webpackChunkName: "lp/:slug.html" */ "@/views/LP_MBA.vue"),
  },
  {
    path: "/online-mba/:slug/thank-you",
    name: "ThankYouMbaView",
    component: () =>
    import(/* webpackChunkName: "online-mba/:slug/thank-you" */ "@/views/thankyou/ThankYouMbaView.vue"),
    props: true,
  },
  {
    path: "/online-bba/:slug/thank-you",
    name: "ThankYouBbaView",
    component: () =>
    import(/* webpackChunkName: "online-bba/:slug/thank-you" */ "@/views/thankyou/ThankYouBbaView.vue"),
    props: true,
  },
  {
    path: "/online-bca/:slug/thank-you",
    name: "ThankYouBcaView",
    component: () =>
    import(/* webpackChunkName: "online-bca/:slug/thank-you" */ "@/views/thankyou/ThankYouBcaView.vue"),
    props: true,
  },
  {
    path: "/online-mca/:slug/thank-you",
    name: "ThankYouMcaView",
    component: () =>
    import(/* webpackChunkName: "online-mca/:slug/thank-you" */ "@/views/thankyou/ThankYouMcaView.vue"),
    props: true,
  },
  {
    path: "/:slug/thank-you",
    name: "ThankYouOnlineView",
    component: () =>
    import(/* webpackChunkName: ":slug/thank-you" */ "@/views/thankyou/ThankYouOnlineView.vue"),
    props: true,
  },
  {
    path: "/lp/thankyou/:slug.html",
    name: "LP_Thankyou",
    component: () =>
    import(/* webpackChunkName: "lp/thankyou/:slug.html" */ "@/views/LP_Thankyou.vue"),
    props: true,
  },
  {
    path: "/lp/online-degree-program.html",
    name: "LP_OnlinePro",
    component: () =>
    import(/* webpackChunkName: "lp/online-degree-program.html" */ "@/views/LP_OnlinePro.vue"),
  },
  {
    path: "/lp/online-degrees.html",
    name: "LP_Online",
    component: () =>
    import(/* webpackChunkName: "lp/online-degrees.html" */ "@/views/LP_Online.vue"),
  },
  {
    path: "/lp/online-ug-degree-program.html",
    name: "LP_Onlineug",
    component: () =>
    import(/* webpackChunkName: "index" */ "@/views/LP_Onlineug.vue"),
  },
  {
    path: "/lp/online-pg-degree-program.html",
    name: "LP_Onlinepg",
    component: () =>
    import(/* webpackChunkName: "lp/online-pg-degree-program.html" */ "@/views/LP_Onlinepg.vue"),
  },
  {
    path: "/lp/thankyou/online-pg-degree-program.html",
    name: "LP_Onlinepgty",
    component: () =>
    import(/* webpackChunkName: "lp/thankyou/online-pg-degree-program.html" */ "@/views/thankyou/LP_Onlinepgty.vue"),
  },
  {
    path: "/lp/thankyou/online-ug-degree-program.html",
    name: "LP_Onlineugty",
    component: () =>
    import(/* webpackChunkName: "lp/thankyou/online-ug-degree-program.html" */ "@/views/thankyou/LP_Onlineugty.vue"),
  },
  {
    path: "/lp/thankyou/online-degree-program.html",
    name: "LP_OnlineProty",
    component: () =>
    import(/* webpackChunkName: "lp/thankyou/online-degree-program.html" */ "@/views/thankyou/LP_OnlineProty.vue"),
  },
  {
    path: "/lp/thankyou/online-degrees.html",
    name: "LP_Onlinety",
    component: () =>
    import(/* webpackChunkName: "lp/thankyou/online-degrees.html" */ "@/views/thankyou/LP_Onlinety.vue"),
  },
  {
    path: "/lp/online-degree",
    name: "OutreachView",
    component: () =>
    import(/* webpackChunkName: "lp/online-degree" */ "@/views/landingView.vue"),
  },
  {
    path: "/lp/thankyou/online-degree",
    name: "OutreachViewty",
    component: () =>
    import(/* webpackChunkName: "lp/thankyou/online-degree" */ "@/views/thankyou/landingViewty.vue"),
  },



  // re-Direction
  { path: "/vignanonline.com", redirect: "https://vignanonline.com" },
  // { path: "/bachelors-business-administration-online-degree", redirect: "https://vignanonline.com" },
  // { path: "/lp/online-bca-degree-program.html", redirect: "https://vignanonline.com" },
  // { path: "/lp/online-ug-degree-program.html", redirect: "https://vignanonline.com" },
  // { path: "/lp/online-bca-computer-science-and-information-technology", redirect: "https://vignanonline.com" },
  { path: "/lp/thankyou/online-mba-program", redirect: "/lp/thankyou/online-mba-program.html" },
  { path: "/lp/thankyou/online-mca-program", redirect: "/lp/thankyou/online-mca-program.html" },
  { path: "/lp/thankyou/online-bba-program", redirect: "/lp/thankyou/online-bba-program.html" },
  { path: "/lp/thankyou/online-mca-degree-program", redirect: "/lp/thankyou/online-mca-degree-program.html" },
  // { path: "/lp/online-bca-degree-program", redirect: "/lp/thankyou/online-bca-degree-program.html" },
  
];

// const router = new VueRouter({
//  mode:'history',
//  base: process.env.BASE_URL,
//  routes
// });

 const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 10, behavior: "smooth" };
  },
 });

 window.isProgrammaticNavigation = false;

 router.beforeEach((to, from, next) => {
   if (to.path === "/search") {
     if (window.isProgrammaticNavigation) {
       window.isProgrammaticNavigation = false;
       next();
     } else {
       next("/");
     }
   } else {
     next();
   }
 });


export default router;





